'use strict';

import BaseMessage from './base-message';

const MESSAGE_TYPE = 'pong';
const FIELDS = [];
const SETTABLE = FIELDS;

export default class PongMessage extends BaseMessage {
	constructor (values) {
		super(MESSAGE_TYPE, values, FIELDS, SETTABLE);
	}
}
