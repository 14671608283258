import { addBreadcrumb } from '@sentry/minimal';
import { Severity } from '@sentry/types';
import Observer from './lib/observer';
import ConnectMessage from './messages/controller/connect';
import PongMessage from './messages/controller/pong';

const pong = new PongMessage();

export default function createWebSocket (store, opts = {}) {
	let observer = null;
	let pingTimer = null;
	let maxMissedPings = -1;
	let missedPings = 0;

	const namespace = opts.namespace || 'controller';

	const pingTimerEvent = () => {
		missedPings += 1;
		if (missedPings > maxMissedPings) {
			if (pingTimer) {
				clearInterval(pingTimer);
				pingTimer = null;
			}
			store.commit(`${namespace}/SOCKET_PING_TIMEOUT`);
			observer.WebSocket.close();
		}
	};

	const handleMessage = (message) => {
		const messageType = message.type;

		if (messageType !== 'ping') {
			addBreadcrumb({
				category: 'WS',
				message: 'Received WS Frame',
				data: message,
				level: Severity.Debug,
			});
		}

		switch (messageType) {
		case 'ping':
			observer.WebSocket.sendObj(pong);
			missedPings = 0;
			if (!pingTimer) {
				const pingIntervalMS = message.sendInterval;
				maxMissedPings = message.maxMissedPings;
				pingTimer = setInterval(pingTimerEvent, pingIntervalMS);
			}
			break;
		case 'authenticate':
			if (message.authenticated && message.permissionConsole) {
				store.commit(`${namespace}/setUseSIP`, !message.useBrowserAudio);
				// NOTE: We injected the `operatorUsername` into the message to make it easier to store
				//       It did not come from the server.
				store.commit(`${namespace}/setSocketConnected`, { isConnected: true, operatorUsername: message.operatorUsername });
			} else {
				store.commit('setError', { message: 'Unable to login to call management server.' }, { root: true });
			}
			break;
		case 'callerStatus':
			// These message types need to do async stuff, so they get `dispatched`
			store.dispatch(`${namespace}/MESSAGE_${messageType}`, message);
			break;
		default:
			// Most message types only change state so they can get `committed`
			store.commit(`${namespace}/MESSAGE_${messageType}`, message);
		}
	};

	return {
		connect (connectionUrl, authToken, operatorUsername, connectionOpts = opts) {
			connectionOpts.store = store;
			connectionOpts.passToStoreHandler = function (eventName, event) {
				// TODO: Implement all cases
				switch (eventName.toUpperCase()) {
				case 'SOCKET_RECONNECT':
					break;
				case 'SOCKET_RECONNECT_ERROR':
					// TODO: Error handling
					break;
				case 'SOCKET_ONCLOSE':
					store.commit(`${namespace}/setSocketConnected`, { isConnected: false, operatorUsername: null });
					break;
				case 'SOCKET_ONOPEN':
					observer.WebSocket.sendObj(new ConnectMessage({
						id: authToken,
						password: null,
						version: 3,
						clientType: 'CONSOLE',
						nonce: '',
					}));
					break;
				case 'SOCKET_ONERROR':
					// TODO: Error handling
					break;
				case 'SOCKET_ONMESSAGE':
					if (event.data) {
						const msg = JSON.parse(event.data);

						if (msg.type === 'authenticate') {
							msg.operatorUsername = operatorUsername;
						}

						handleMessage(msg);
					}
					break;
				}
			};

			observer = new Observer(connectionUrl, connectionOpts);
		},
		disconnect () {
			if (pingTimer) {
				clearInterval(pingTimer);
				pingTimer = null;
			}

			if (observer && observer.reconnection) {
				observer.reconnection = false;
			}
			if (observer) {
				observer.WebSocket.close();
				observer = null;
			}
		},
		send (message) {
			if (observer && observer.WebSocket && observer.WebSocket.readyState === WebSocket.OPEN) {
				addBreadcrumb({
					category: 'WS',
					message: 'Sending WS Frame',
					data: message,
					level: Severity.Debug,
				});
				observer.WebSocket.sendObj(message);
			}
		},
		isOpen () {
			return observer?.WebSocket?.readyState === WebSocket.OPEN;
		},
	};
}
