import { addBreadcrumb } from '@sentry/minimal';
import { Severity as SentrySeverity } from '@sentry/types';
import moment from 'moment';
import Vue from 'vue';
import M from '../mutation-types';

let socket;

const SOCKET_URL = '_socket';

export default function webSocketPlugin(store) {
	function send(obj) {
		socket.send(JSON.stringify(obj));
	}

	function wsOnOpen(event) {
		addBreadcrumb({
			category: 'call',
			message: 'Opened chat websocket',
		});
		// register with the WS server
		send({
			type: 'init',
			UserId: store.state.operator.userid,
			token: store.state.operator.api_session_token,
			userinfos: {
				UserId: store.state.operator.userid,
			},
		});
	}

	function wsOnClose(event) {
		Vue.nextTick().then(function() {
			store.commit('setErrorIfNone', `Connection to server closed. (CODE ${event.code}; ${event.reason})`);
		});
	}

	function wsOnMessage(event) {
		// There are lots of cases we can ignore, such as:
		//  - `mobileChatRequest`
		//  - `initmobile`
		// This is because those would have been sent before we opened our
		// WebSocket session, so in theory we will never receive them for our
		// call and we don't care about receiving them for anyone else's call.
		const msg = JSON.parse(event.data);
		const type = msg.Type || msg.type; // For some reason we send both uppercase and lowercase!
		switch (type) {
		case 'onlineConsoles':
			// Check that we have successfully logged in
			for (var i in msg.WC) {
				var session = msg.WC[i];
				if (session.UserInfos.Token === store.state.operator.api_session_token) {
					addBreadcrumb({
						category: 'call',
						message: 'Found ourselves in onlineConsoles list',
					});
					store.commit('chatCall/set_connected', true);
					return;
				}
			}
			break;
		case 'terminateChatRequest':
			if (msg.CallerId === store.state.callData.UserPhoneNumber) {
				switch (msg.reason) {
				case 'other_console':
					// Another console got the call first
					store.commit('setError', { message: 'Another operator has answered this chat session' }, { root: true });
					break;
				case 'no_exist':
					// The mobile side has disappeared
					store.commit('setError', { message: 'This chat session has already ended.' }, { root: true });
					break;
				default:
					addBreadcrumb({
						category: 'call',
						message: 'Error from Chat WS',
						data: msg,
						level: SentrySeverity.Error,
					});
					store.commit('setError', { message: 'An error has occured while answering this call. (WS_TERMINATE)' }, { root: true });
				}
				socket.close();
			}
			break;
		case 'mobileChatRoomCreated':
			addBreadcrumb({
				category: 'call',
				message: 'Chat Room Created',
				data: msg,
				level: SentrySeverity.Debug,
			});
			store.commit('chatCall/set_chat_room_info', msg);
			store.commit('hide_loader');
			break;
		case 'chatMsgFromMobile':
			store.commit(M.CALLDATA_UPDATE_USER_LOCATION, { lat: msg.CurrentLocation.latitude, lng: msg.CurrentLocation.longitude });
			store.commit('chatCall/chat_messages/add_remote', {
				source: msg.phoneNumber,
				destination: store.getters['operator/controllerUserId'],
				timestamp: moment(),
				message: msg.msg,
			});
			addBreadcrumb({
				category: 'call',
				message: 'Received text message from Chat WS',
				data: msg,
				level: SentrySeverity.Debug,
			});
			break;
		case 'mobileChatClosed':
			store.commit('setError', { message: `Chat call with ${store.state.callData.UserPhoneNumber} has ended.`, title: 'Call Ended' }, { root: true });
			socket.close();
		}
	}

	store.subscribe((mutation, state) => {
		switch (mutation.type) {
		case M.CALLDATA_SET_DATA:
			// Check if this chat has already been handled before we try to do anything else.
			switch (mutation.payload.ChatStatus) {
			case 'STARTED':
				store.commit('show_loader');
				var ws_addr = state.apiServer
					.replace('https://', 'wss://')
					.replace('http://', 'ws://');

				socket = new WebSocket(ws_addr + SOCKET_URL);
				socket.addEventListener('open', wsOnOpen);
				socket.addEventListener('message', wsOnMessage);
				socket.addEventListener('close', wsOnClose);
				break;
			case 'ONGOING':
				store.commit('setError', { message: 'This chat session has already been answered by another operator.' }, { root: true });
				break;
			default:
				store.commit('setError', { message: 'This chat session has already ended.' }, { root: true });
			}
			break;
		case 'chatCall/set_connected':
			store.dispatch('chatCall/try_answer_call');
			break;
		case 'chatCall/endCall':
			store.commit('setError', { message: `Chat call with ${store.state.callData.UserPhoneNumber} has ended.`, title: 'Call Ended' }, { root: true });
			socket.close();
			break;
		}
	});
}
