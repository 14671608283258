<template>
	<div id="parent">
		<ErrorPane v-if="showError" :message="errorMessage" :title="errorTitle"></ErrorPane>
		<template v-else>
			<div id="app" v-if="loggedIn">
				<ButtonRow id="top-row" :user="operator"></ButtonRow>
				<div id="main-window">
					<div id="left-column">
						<VideoPane class="flexChild" v-if="isVideoCall"></VideoPane>
						<ChatPane class="flexChild" :messages="chatMessages"></ChatPane>
					</div>
					<div id="right-column">
						<ProfilePane class="flexChild" :data="profile"></ProfilePane>
						<MapPane class="flexChild"></MapPane>
					</div>
				</div>
			</div>
			<LoginForm v-else></LoginForm>
			<BlockUI v-if="loading" :message="loadingMessage">
				<font-awesome-icon icon="cog" spin size="4x" />
			</BlockUI>
		</template>
	</div>
</template>

<script>
import VideoPane from './components/VideoPane.vue';
import ChatPane from './components/ChatPane.vue';
import MapPane from './components/MapPane.vue';
import ProfilePane from './components/ProfilePane.vue';
import ButtonRow from './components/ButtonRow.vue';
import LoginForm from './components/LoginForm.vue';
import BlockUI from './components/BlockUI.vue';
import ErrorPane from './components/ErrorPane.vue';
import { processInitialData } from './lib/data-processing';

export default {
	name: 'app',
	created: function() {
		window.addEventListener('beforeunload', this.beforeUnload);

		processInitialData(this);
	},
	computed: {
		loggedIn() { return this.$store.getters.isLoggedIn; },
		loading() { return this.$store.state.showLoader; },
		showError() { return this.$store.getters.showError; },
		errorTitle() { return this.$store.state.errorTitle; },
		errorMessage() { return this.$store.state.errorMessage; },
		operator() { return this.$store.state.operator; },
		profile() { return this.$store.state.callData; },
		chatMessages() {
			switch (this.$store.state.callData.NotificationType) {
			case 'VIDEO':
				return this.$store.getters['videoCalls/textMessages'];
			case 'CHAT':
				return this.$store.state.chatCall.chat_messages.messages;
			default:
				return [];
			}
		},
		isVideoCall() {
			return this.$store.state.callData.NotificationType === 'VIDEO';
		},
	},
	props: {
		loadingMessage: String,
	},
	components: {
		BlockUI,
		VideoPane,
		ChatPane,
		MapPane,
		ProfilePane,
		ButtonRow,
		LoginForm,
		ErrorPane,
	},
	methods: {
		beforeUnload(evt) {
			if (!this.showError) {
				evt.preventDefault();
				// NOTE: In most browsers, this message will never actually be seen.
				var msg = 'You are currently working on this call. Are you sure you want to close it?';
				evt.returnValue = msg;
				return msg;
			}
		},
	},
};
</script>

<style lang="scss">
@import '~styles/fibonacci';
@import '~styles/colors';

html, body, #parent, #app {
	height: 100%;
	overflow: hidden;
	margin: 0;
	padding: 0;

	@extend %bg-fade;

	font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
	font-size: 14px;
	line-height: 1.42857143;
}

#app, #left-column, #right-column {
	@extend %columnParent;
}

#main-window {
	@extend %rowParent;
}

#app, #main-window, #bottom-row, #left-column, #right-column {
	@extend .flexChild;
}

#top-row {
	flex: 0 0 auto;
}

</style>
