import L from 'leaflet';
import defaultIcon from '../assets/map-icons/branch_closed.png';
import iconShadow from '../assets/map-icons/pin-shadow.svg';

L.BranchIcon = L.Icon.extend({
	options: {
		extraClasses: [],
		// Default to "closed" status icon
		iconUrl: defaultIcon,
		iconSize: [30, 55],
		iconAnchor: [15, 55],
		shadowUrl: iconShadow,
		shadowSize: [30, 27],
		shadowAnchor: [0, 26],
	},
});

L.BranchMarker = L.Marker.extend({
	options: {
		icon: new L.BranchIcon(),
		// Default to blue, for 'closed' status
		status_color: 'blue',
		status_active: false,

		pulserSize: [27, 27],
		pulserAnchor: [14, 53],
	},

	onRemove: function(map) {
		if (this.dragging && this.dragging.enabled()) {
			this.options.draggable = true;
			this.dragging.removeHooks();
		}
		delete this.dragging;

		if (this._zoomAnimated) {
			map.off('zoomanim', this._animateZoom, this);
		}

		this._removeIcon();
		this._removeShadow();
		this._removePulser();
	},

	update: function() {
		if (this._icon && this._map) {
			// Every time we update, check that the icon is the correct one
			this._updateIcon();

			var pos = this._map.latLngToLayerPoint(this._latlng).round();
			this._setPos(pos);
		}

		return this;
	},

	/**
	 * Choose the icon to use based on the status we have set,
	 * and update the icon options.
	 */
	_determineIcon: function() {
		var statusImg = getStatusImage(this.options.status);

		this.options.icon.options.iconUrl = statusImg.img;
		this.options.status_color = statusImg.class;
	},

	/**
	 * Initialize the icon components.
	 *
	 * Overridden to handle determining our icons as well as adding the active pulse.
	 */
	_initIcon: function() {
		this._determineIcon();

		// BEGIN copied from parent
		var options = this.options;
		var classToAdd = 'leaflet-zoom-' + (this._zoomAnimated ? 'animated' : 'hide');

		var icon = options.icon.createIcon(this._icon);
		var addIcon = false;

		// if we're not reusing the icon, remove the old one and init new one
		if (icon !== this._icon) {
			if (this._icon) {
				this._removeIcon();
			}
			addIcon = true;

			if (options.title) {
				icon.title = options.title;
			}

			if (icon.tagName === 'IMG') {
				icon.alt = options.alt || '';
			}
		}

		L.DomUtil.addClass(icon, classToAdd);

		if (options.keyboard) {
			icon.tabIndex = '0';
		}

		this._icon = icon;

		if (options.riseOnHover) {
			this.on({
				mouseover: this._bringToFront,
				mouseout: this._resetZIndex,
			});
		}

		var newShadow = options.icon.createShadow(this._shadow);
		var addShadow = false;

		if (newShadow !== this._shadow) {
			this._removeShadow();
			addShadow = true;
		}

		if (newShadow) {
			L.DomUtil.addClass(newShadow, classToAdd);
			newShadow.alt = '';
		}
		this._shadow = newShadow;

		if (options.opacity < 1) {
			this._updateOpacity();
		}

		if (addIcon) {
			this.getPane().appendChild(this._icon);
		}
		this._initInteraction();
		if (newShadow && addShadow) {
			this.getPane('shadowPane').appendChild(this._shadow);
		}
		// END copied from parent

		if (options.status_active) {
			var newPulser = this._createPulser(this._pulser);
			if (newPulser !== this._pulser) {
				L.DomUtil.addClass(newPulser, classToAdd);
			}
			this._pulser = newPulser;
		} else {
			if (this._pulser) {
				L.DomUtil.remove(this._pulser);
			}
			this._pulser = null;
		}
	},

	_removePulser: function() {
		if (this._pulser) {
			L.DomUtil.remove(this._pulser);
		}
		this._pulser = null;
	},

	_createPulser: function(el) {
		el = el || document.createElement('div');

		// Reset the classes
		el.className = 'branch-icon-pulser';

		// Remove and recreate child element
		while (el.lastChild) {
			el.removeChild(el.lastChild);
		}

		var innerEl = document.createElement('div');
		el.appendChild(innerEl);

		// Add the appropriate color and the animation
		L.DomUtil.addClass(innerEl, 'pulse');
		L.DomUtil.addClass(innerEl, this.options.status_color);

		// Set pulser positioning
		// Based on Icon._setIconStyles
		var sizeOption = this.options.pulserSize;
		if (typeof sizeOption === 'number') {
			sizeOption = [sizeOption, sizeOption];
		}

		var size = L.point(sizeOption);
		var anchor = L.point(this.options.pulserAnchor || (size && size.divideBy(2, true)));

		if (anchor) {
			el.style.marginLeft = (-anchor.x) + 'px';
			el.style.marginTop = (-anchor.y) + 'px';
		}

		if (size) {
			el.style.width = size.x + 'px';
			el.style.height = size.y + 'px';
		}

		return el;
	},

	_updateIcon: function() {
		this._determineIcon();

		var options = this.options;

		var icon = options.icon.createIcon(this._icon);

		// if we're not reusing the icon, remove the old one and init new one
		if (icon !== this._icon) {
			if (this._icon) {
				this._removeIcon();
				this.getPane().appendChild(icon);
			}

			if (options.title) {
				icon.title = options.title;
			}

			if (icon.tagName === 'IMG') {
				icon.alt = options.alt || '';
			}
		}

		this._icon = icon;
		this._initInteraction();

		// Right now, the shadow never changes, so we don't bother with it here.

		if (options.status_active) {
			this._pulser = this._createPulser(this._pulser);
			this.getPane('branchPulses').appendChild(this._pulser);
		} else {
			if (this._pulser) {
				L.DomUtil.remove(this._pulser);
			}
			this._pulser = null;
		}
	},

	_setPos: function(pos) {
		L.DomUtil.setPosition(this._icon, pos);

		if (this._shadow) {
			L.DomUtil.setPosition(this._shadow, pos);
		}

		if (this._pulser) {
			L.DomUtil.setPosition(this._pulser, pos);
		}

		this._zIndex = pos.y + this.options.zIndexOffset;

		this._resetZIndex();
	},
});

function getStatusImage(status) {
	switch (status) {
	case 'Opened':
		return { img: require('../assets/map-icons/branch_opened.png'), class: 'green' };
	case 'Opening':
		return { img: require('../assets/map-icons/branch_opened.png'), class: 'green' };
	case 'Opening Trouble':
		return { img: require('../assets/map-icons/trouble_opened.png'), class: 'red' };
	case 'Opening Time Adding':
		return { img: require('../assets/map-icons/branch_opened.png'), class: 'green' };
	case 'Closed':
		return { img: require('../assets/map-icons/branch_closed.png'), class: 'blue' };
	case 'Closing':
		return { img: require('../assets/map-icons/branch_closed.png'), class: 'blue' };
	case 'Closing Trouble':
		return { img: require('../assets/map-icons/trouble_closed.png'), class: 'red' };
	case 'Closing Time Adding':
		return { img: require('../assets/map-icons/branch_closed.png'), class: 'blue' };
	case 'Serviced':
		return { img: require('../assets/map-icons/branch_serviced.png'), class: 'orange' };
	case 'Servicing':
		return { img: require('../assets/map-icons/branch_serviced.png'), class: 'orange' };
	case 'Servicing Trouble':
		return { img: require('../assets/map-icons/trouble_serviced.png'), class: 'red' };
	case 'Servicing Time Adding':
		return { img: require('../assets/map-icons/branch_serviced.png'), class: 'orange' };
	case 'Panic - Video':
		return { img: require('../assets/map-icons/trouble_video.png'), class: 'red' };
	case 'Panic - Chat':
		return { img: require('../assets/map-icons/trouble_chat.png'), class: 'red' };
	}
}
