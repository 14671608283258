import createWebSocket from '@/repositories/websockets/controller';
import AcceptCallMessage from '@/repositories/websockets/messages/controller/accept-call';
import EndCallMessage from '@/repositories/websockets/messages/controller/end-call';
import TextMessage from '@/repositories/websockets/messages/controller/text';
import IceCandidateMessage from '@/repositories/websockets/messages/controller/webrtc/ice-candidate';
import ICEServersRequestMessage from '@/repositories/websockets/messages/controller/webrtc/ice-servers-request';
import OfferSDPMessage from '@/repositories/websockets/messages/controller/webrtc/offer';
import Vue from 'vue';

// NOTE: We only ever handle one call in this application, but we are trying
// to keep the code in sync with the other project as much as possible.

export default function createWebSocketPlugin (opts = {}) {
	return function (store) {
		const namespace = opts.namespace || 'videoCalls';

		const socket = createWebSocket(store, opts);

		const unsubscribe = store.subscribe((mutation, state) => {
			switch (mutation.type) {
			case `${namespace}/setSocketConnected`: {
				if (mutation.payload?.isConnected) {
					Vue.nextTick(() => {
						store.dispatch('videoCalls/tryAnswerCall');
					});
				} else {
					unsubscribe();
				}
				store.commit('hide_loader');
				break;
			}
			case 'callData/set_data': {
				// Once we know the operator information,
				// we have everything we need to try to begin the call.
				const url = `wss://${state.callData.ControllerAddress}/console`;
				const authToken = state.operator.rawToken;
				const operatorUsername = state.operator.email.split('@')[0];
				store.commit('show_loader');
				socket.connect(url, authToken, operatorUsername);
				break;
			}
			case `${namespace}/requestIceServers`:
				socket.send(new ICEServersRequestMessage());
				break;
			case `${namespace}/answerCall`: {
				socket.send(new AcceptCallMessage({ callerID: state.callData.UserPhoneNumber }));
				break;
			}
			case `${namespace}/addLocalMessage`:
				socket.send(new TextMessage({
					source: mutation.payload.source,
					destination: mutation.payload.destination,
					message: mutation.payload.message,
					timestamp: mutation.payload.timestamp.toSQL({ includeOffset: false }),
				}));
				break;
			case `${namespace}/endCall`: {
				socket.send(new EndCallMessage({ callerID: store.state.callData.UserPhoneNumber }));
				break;
			}
			case `${namespace}/setLocalOffer`:
				socket.send(new OfferSDPMessage({
					sdp: mutation.payload.sdp,
				}));
				break;
			case `${namespace}/foundLocalIceCandidate`:
				if (mutation.payload) {
					socket.send(new IceCandidateMessage({
						candidate: mutation.payload.candidate,
						sdpMid: mutation.payload.sdpMid,
						sdpMLineIndex: mutation.payload.sdpMLineIndex,
					}));
				}
				// TODO: Do we need to send the empty candidate to signal completion?
				break;
			case `${namespace}/MESSAGE_error`: {
				const errorText = mutation.payload.errorText;

				if (/is no longer in system$/.test(errorText)) {
					store.commit('setError', { message: `Call with ${state.callData.UserPhoneNumber} has already ended.` }, { root: true });
				} else if (/^Call already answered by/.test(errorText)) {
					store.commit('setError', errorText, { root: true });
				} else if (errorText.includes(' has logged in again. Closing connection')) {
					store.commit('setError', { message: 'Connection closed: a single user cannot have two instances of this site open at once.' }, { root: true });
				} else {
					store.commit('setError', { message: 'An error has occured while answering this call: ' + errorText }, { root: true });
				}
				break;
			}
			case 'setError':
			case 'setErrorIfNone':
				socket.disconnect();
				// This is in an `if` to prevent infinite loops
				if (!state.errorMessage) {
					store.commit('setErrorIfNone', { message: 'The server has closed your connection. If you believe this is not correct, refresh the page to log in again.' }, { root: true });
				}
				break;
			}
		});
	};
}
