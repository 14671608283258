'use strict';

import BaseMessage from '../base-message';

const MESSAGE_TYPE = 'requestICEServers';
const FIELDS = [];

export default class ICEServersRequestMessage extends BaseMessage {
	constructor (values) {
		super(MESSAGE_TYPE, values, FIELDS);
	}
}
