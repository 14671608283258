<template>
	<l-map ref="map" :zoom="zoom" :center="center" :bounds="bounds" style="width: 100%; height: 100%">
		<l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
		<l-marker v-for="marker in markers" :key="marker.id" :visible="marker.visible" :draggable="marker.draggable" :lat-lng="marker.position" :icon="marker.icon">
			<l-tooltip :content="marker.tooltip" />
      </l-marker>
	</l-map>
</template>

<script>
import L from 'leaflet';
import { LMap, LTileLayer, LMarker, LTooltip } from 'vue2-leaflet';
import M from '../store/mutation-types';
import eventBus from '../lib/eventBus';

export default {
	name: 'MapPane',
	components: {
		LMap,
		LTileLayer,
		LMarker,
		LTooltip,
	},
	data() {
		return {
			url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
			attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
			zoom: 4,
			center: [40, -98],
			bounds: undefined,
		};
	},
	computed: {
		markers() {
			return this.$store.getters[M.CALLDATA_MAP_MARKERS];
		},
	},
	mounted() {
		const self = this;
		eventBus.$on('recenter-map', () => {
			const bounds = new L.LatLngBounds(self.markers.map(m => m.position));
			self.bounds = bounds;
		});
	},
};
</script>

<style scoped lang="scss">
@import "~leaflet/dist/leaflet.css";
</style>
