'use strict';

import BaseMessage from './base-message';

const SOURCE = 'source';
const DESTINATION = 'destination';
const MESSAGE = 'message';
const TIMESTAMP = 'timestamp';
const CLIENT_TYPE = 'clientType';

const MESSAGE_TYPE = 'text';
const FIELDS = [SOURCE, DESTINATION, MESSAGE, TIMESTAMP, CLIENT_TYPE];
// clientType cannot be changed once it is set
const SETTABLE = [SOURCE, DESTINATION, MESSAGE, TIMESTAMP];

export default class TextMessage extends BaseMessage {
	constructor (values) {
		super(MESSAGE_TYPE, values, FIELDS, SETTABLE);
	}
}
