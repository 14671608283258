import 'webrtc-adapter';
import _merge from 'lodash.merge';
import { addBreadcrumb } from '@sentry/minimal';
import { Severity as SentrySeverity } from '@sentry/types';

// Based on https://github.com/otalk/localmedia/blob/777ac804c97ae9c9f359b4f341dd110585b6cfb2/localmedia.js

function isAllTracksEnded (stream) {
	var isAllTracksEnded = true;
	stream.getTracks().forEach(function (t) {
		isAllTracksEnded = t.readyState === 'ended' && isAllTracksEnded;
	});
	return isAllTracksEnded;
}

export default class LocalMedia {
	constructor(opts) {
		const defaults = {
			detectSpeakingEvents: false,
			media: {
				audio: true,
				video: true,
			},
			audioFallback: true,
		};

		this.config = _merge(
			{},
			defaults,
			opts,
		);

		this.localStreams = [];
	}

	get streams() {
		return this.localStreams;
	}

	async start(overrideConstraints) {
		const constraints = overrideConstraints || this.config.media;

		// If both `video` and `audio` are set to false,
		// we don't need local media, probably because
		// we are using SIP.
		if (constraints.video || constraints.audio) {
			try {
				addBreadcrumb({
					category: 'webrtc',
					message: 'Connecting local media',
					data: constraints,
					level: SentrySeverity.Debug,
				});
				const stream = await navigator.mediaDevices.getUserMedia(constraints);
				this.localStreams.push(stream);

				stream.getTracks().forEach(function (track) {
					track.addEventListener('ended', function () {
						if (isAllTracksEnded(stream)) {
							this.removeStream(stream);
						}
					});
				});

				return stream;
			} catch (err) {
				// Fallback for users without a camera to use audio only
				if (this.config.audioFallback && err.name === 'DevicesNotFoundError' && constraints.video !== false) {
					addBreadcrumb({
						category: 'webrtc',
						message: 'Retrying connecting local media',
						data: constraints,
						level: SentrySeverity.Debug,
					});
					constraints.video = false;
					const stream = await navigator.mediaDevices.getUserMedia(constraints);
					this.localStreams.push(stream);

					stream.getTracks().forEach(function (track) {
						track.addEventListener('ended', function () {
							if (isAllTracksEnded(stream)) {
								this.removeStream(stream);
							}
						});
					});

					return stream;
				} else {
					// TODO: Is there any other error handling we want to do here?
					// Rethrow the original error
					throw err;
				}
			}
		} else {
			addBreadcrumb({
				category: 'webrtc',
				message: 'Did not request local media',
				data: constraints,
				level: SentrySeverity.Debug,
			});
			return false;
		}
	}

	stop(stream) {
		// Stop a specific one, or stop them all?
		if (stream) {
			stream.getTracks().forEach(function (track) { track.stop(); });
			var idx = this.localStreams.indexOf(stream);
			if (idx > -1) {
				this.localStreams.splice(idx, 1);
			}
		} else {
			this.localStreams.forEach(function (stream) {
				stream.getTracks().forEach(function (track) { track.stop(); });
			});
			this.localStreams = [];
		}
	}

	mute() {
		this.audioEnabled = false;
	}

	unmute() {
		this.audioEnabled = true;
	}

	pauseVideo() {
		this.videoEnabled = false;
	}

	resumeVideo() {
		this.videoEnabled = true;
	}

	pause() {
		this.videoEnabled = false;
		this.audioEnabled = false;
	}

	resume() {
		this.videoEnabled = true;
		this.audioEnabled = true;
	}

	set audioEnabled(bool) {
		this.localStreams.forEach(function (stream) {
			stream.getAudioTracks().forEach(function (track) {
				track.enabled = !!bool;
			});
		});
	}

	set videoEnabled(bool) {
		this.localStreams.forEach(function (stream) {
			stream.getVideoTracks().forEach(function (track) {
				track.enabled = !!bool;
			});
		});
	}

	get audioEnabled() {
		var enabled = true;
		this.localStreams.forEach(function (stream) {
			stream.getAudioTracks().forEach(function (track) {
				enabled = enabled && track.enabled;
			});
		});
		return enabled;
	}

	get videoEnabled() {
		var enabled = true;
		this.localStreams.forEach(function (stream) {
			stream.getVideoTracks().forEach(function (track) {
				enabled = enabled && track.enabled;
			});
		});
		return enabled;
	}

	removeStream(stream) {
		const idx = this.localStreams.indexOf(stream);
		if (idx > -1) {
			this.localStreams.splice(idx, 1);
		}
	}
}
