<template>
	<div class="root">
		<video id="remoteVideo" autoplay></video>
		<audio id="localAudio" autoplay></audio>
	</div>
</template>

<script>
export default {
	name: 'VideoPane',
	props: {
		msg: String,
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '~styles/colors';

.root {
	background: $medium-gray;
	position: relative;
}

#remoteVideo {
	position: absolute;
	height: 100%;
	width: 100%;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}

</style>
