import { _names as M } from '../mutation-types';

export default {
	namespaced: true,

	state: {
		rawToken: undefined,

		email: undefined,
		userid: undefined,
		first_name: undefined,
		last_name: undefined,
		role_name: undefined,
		role_display: undefined,
		tokenExpiration: undefined,

		api_session_token: undefined,
	},
	getters: {
		controllerUserId(state) {
			return state.email ? state.email.split('@')[0] : undefined;
		},
	},
	mutations: {
		[M.operator.SET_API_SESSION](state, token) {
			state.api_session_token = token;
		},
		[M.operator.SET_TOKEN](state, token) {
			state.rawToken = token;
		},
		[M.operator.SET_DATA](state, operator) {
			state.email = operator.email;
			state.userid = operator.sub;
			state.tokenExpiration = operator.exp;
			state.first_name = operator.first_name;
			state.last_name = operator.last_name;
			state.role_name = operator.role_name;
			state.role_display = operator.role_name_display;
		},
	},
};
