'use strict';

import BaseMessage from '../base-message';

const CANDIDATE = 'candidate';
const SDP_MID = 'sdpMid';
const SDP_M_LINE_INDEX = 'sdpMLineIndex';

const MESSAGE_TYPE = 'iceCandidate';
const FIELDS = [CANDIDATE, SDP_MID, SDP_M_LINE_INDEX];
const SETTABLE = FIELDS;

export default class IceCandidateMessage extends BaseMessage {
	constructor (values) {
		super(MESSAGE_TYPE, values, FIELDS, SETTABLE);
	}
}
