<template>
	<div class="button-bar">
		<div class="logo"><img src="../assets/navlogo.png"></div>
		<div class="buttons">
			<!--<button class="hold"><font-awesome-icon icon="pause" /> Hold</button>-->
			<button @click="endCall" class="end"><font-awesome-icon icon="phone-slash" /> End Call</button>
		</div>
		<div class="user-info">
			<div class="name-and-role">
				<div>{{user_name}}</div>
				<div>{{user_role}}</div>
			</div>
			<img src="../assets/profilepic.png">
		</div>
	</div>
</template>

<script>
export default {
	name: 'ButtonRow',
	props: {
		user: Object,
	},
	computed: {
		user_name() { return this.user.first_name + ' ' + this.user.last_name; },
		user_email() { return this.user.email; },
		user_role() { return this.user.role_display; },
	},
	methods: {
		endCall() {
			// These need to be done differently because the video call commits
			// it directly and then it gets sent over the WebSocket while the
			// chat call sends it as a POST, and when that is successful commits
			// it and it is also sent over the WebSocket.
			switch (this.$store.state.callType) {
			case 'VIDEO':
				this.$store.commit('videoCalls/endCall');
				break;
			case 'CHAT':
				this.$store.dispatch('chatCall/end_chat');
				break;
			}
		},
	},
};
</script>

<style scoped lang="scss">
.button-bar, .buttons, .user-info {
	display: flex;
}

.button-bar {
	color: #fff;
	display: flex;
	background: #1f3159;
	height: 35px;
	align-items: center;
	padding: 10px;
	justify-content: space-between;
}

.logo {
	order: 2;
	flex: 0;
}

.buttons {
	order: 1;
	flex: 1;
	justify-content: flex-start;

	button {
		cursor: pointer;
		box-sizing: border-box;
		border: 2px solid #4c79bc;
		border-radius: 15px;
		background: rgba(255,255,255,0.8);
		color: #1f3159;
		padding: 8px 12px;
		margin-right: 10px;
		font-weight: bold;

		transition: background-color 0.3s cubic-bezier(0, 0, 0, 0), color 0.3s cubic-bezier(0, 0, 0, 0), width 0.3s cubic-bezier(0, 0, 0, 0), border-width 0.3s cubic-bezier(0, 0, 0, 0), border-color 0.3s cubic-bezier(0, 0, 0, 0);

		&.hold {
			color: #666;
		}

		&.end {
			color: #c00;
		}

		&:hover {
			background: #fff;
		}
	}
}

.user-info {
	order: 3;
	flex: 1;
	justify-content: flex-end;

	img {
		height: 30px;
		width: 30px;
	}

	.name-and-role {
		margin-right: 10px;
		text-align: right;

		div:nth-child(2) {
			font-size: 10px;
			margin-top: -2px;
		}
	}
}

.buttons:after,
.user-info:before {
	content: '';
	//display: inline-block;
}
</style>
