'use strict';

import BaseMessage from './base-message';

const CALLER_ID = 'callerID';

const MESSAGE_TYPE = 'acceptCall';
const FIELDS = [CALLER_ID];
// All fields of this message are settable
const SETTABLE = FIELDS;

export default class AcceptCallMessage extends BaseMessage {
	constructor (values) {
		// Allow passing in only the caller ID
		if (typeof values === 'string') {
			values = { [CALLER_ID]: values };
		}
		super(MESSAGE_TYPE, values, FIELDS, SETTABLE);
	}
}
