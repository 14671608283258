import { DateTime, Duration } from 'luxon';

const TIMESTAMP = Object.assign(DateTime.DATETIME_FULL_WITH_SECONDS, { month: 'short' });
const DATE = DateTime.DATE_SHORT;
const TIME = DateTime.TIME_WITH_SHORT_OFFSET;

export function formattedDateTime (timestamp, type, tz = null) {
	let format;

	if (type === 'timestamp') {
		format = TIMESTAMP;
	} else if (type === 'time') {
		format = TIME;
	} else if (type === 'date') {
		format = DATE;
	}

	if (tz) {
		return DateTime.fromISO(timestamp, { zone: 'utc' }).setZone(tz).toLocaleString(format);
	} else {
		return DateTime.fromISO(timestamp, { zone: 'utc' }).toLocal().toLocaleString(format);
	}
}

export function formattedDateTimeFromSQL (timestamp, type, tz = null) {
	let format;

	if (type === 'timestamp') {
		format = TIMESTAMP;
	} else if (type === 'time') {
		format = TIME;
	} else if (type === 'date') {
		format = DATE;
	}

	if (tz) {
		return DateTime.fromSQL(timestamp, { zone: 'utc' }).setZone(tz).toLocaleString(format);
	} else {
		return DateTime.fromSQL(timestamp, { zone: 'utc' }).toLocal().toLocaleString(format);
	}
}

export function formatDuration (milliseconds) {
	return Duration.fromISOTime(milliseconds).as('minutes');
}

export function getNow () {
	return DateTime.now().toLocaleString(TIMESTAMP);
}

export function getNowSQL () {
	return DateTime.now().toSQL();
}

export function secondsToTimer (seconds) {
	const a = Math.abs(seconds);
	let time;
	if (seconds < 0) {
		time = Duration.fromMillis(a * 1000);
	} else {
		time = Duration.fromMillis(seconds * 1000);
	}

	return time.toFormat('hh:mm:ss');
}

export function addTime (timestamp, object) {
	return DateTime.fromISO(timestamp).plus(object);
}

export function differenceTimeStamps (start, end) {
	const startObj = DateTime.fromISO(start);
	const endObj = DateTime.fromISO(end);
	return endObj.diff(startObj).as('seconds');
}
