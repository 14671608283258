import Modernizr from 'modernizr';

Modernizr.addTest('paramlessrtclocaldescription', function () {
	try {
		return window.RTCPeerConnection.prototype.setLocalDescription.length === 0;
	} catch (err) {
		// This can only happen if the browser doesn't support RTCPeerConnection, and
		// we have a separate test for that, but we don't want this test to crash
		// the script.
		return false;
	}
});

export function waitForTests (tests) {
	const testNames = Object.keys(tests);
	const testCount = testNames.length;
	const testsPassed = [];
	const testsFailed = [];

	return new Promise((resolve, reject) => {
		const checkIfDone = function () {
			if (testsPassed.length + testsFailed.length === testCount) {
				if (testsFailed.length === 0) {
					resolve();
				} else {
					reject(testsFailed);
				}
			}
		};

		const makeTestFunction = function (message) {
			return function (result) {
				if (result) {
					testsPassed.push(message);
				} else {
					testsFailed.push(message);
				}
				checkIfDone();
			};
		};

		testNames.forEach(name => {
			Modernizr.on(name, makeTestFunction(tests[name]));
		});
	});
}
