import chat_messages from './chat-messages';

import { acceptChat, sendChatMessage, endChat } from '../../lib/safebanker-api';

export default {
	namespaced: true,

	modules: {
		chat_messages,
	},
	state: {
		connected: false,

		sent_answer_request: false,

		chat_room_id: undefined,
		mobile_token: undefined,
	},
	actions: {
		try_answer_call: acceptChat,
		post_chat: sendChatMessage,
		end_chat: endChat,
	},
	mutations: {
		answer(state) {
			state.sent_answer_request = true;
		},
		set_connected(state, isConnected) {
			state.connected = isConnected;
		},
		set_chat_room_info(state, chat_room) {
			state.chat_room_id = chat_room.ChatRoomId;
			state.mobile_token = chat_room.MOBILEtoken;
		},
		endCall() {
			// NOOP in here.
			// Used to trigger the end of a call through the WebSocket plugin.
		},
	},
};
