import Vue from 'vue';
import Vuex from 'vuex';

import modules from './modules';
import { doLogin } from '../lib/safebanker-api';
import { processOperatorToken } from '../lib/data-processing';
import { _names as M } from './mutation-types';

import ChatCall from './modules/chat-call';
import VideoCalls from './modules/video-calls';
import ChatPlugin from './plugins/chat-websocket';
import createWebRTCPlugin from './plugins/browser-webrtc';
import createControllerWebSocketPlugin from './plugins/controller-websocket';
import { addBreadcrumb, captureMessage as sentryCaptureMessage } from '@sentry/minimal';
import { Severity as SentrySeverity } from '@sentry/types';

Vue.use(Vuex);

function handleError (state, payload) {
	// Backward compatibility
	if (payload instanceof String) {
		state.errorMessage = payload;
		return;
	}

	const {
		message,
		title = 'Error',
		report = true,
	} = payload;

	state.errorMessage = message;

	state.errorTitle = title;

	if (report) {
		sentryCaptureMessage(message);
	}
}

const store = new Vuex.Store({
	modules,
	state: {
		showLoader: 0,
		errorMessage: undefined,
		errorTitle: 'Error',

		apiServer: undefined,
		alertId: undefined,

		callType: undefined,

		warnings: undefined,
	},
	actions: {
		doLogin,
		processOperatorToken,
	},
	mutations: {
		[M.SHOW_LOADER](state) {
			state.showLoader++;
		},
		[M.HIDE_LOADER](state) {
			if (state.showLoader > 0) {
				state.showLoader--;
			}
		},
		setErrorIfNone (state, payload) {
			if (state.errorMessage) {
				return;
			}

			handleError(state, payload);
		},
		setError (state, payload) {
			handleError(state, payload);
		},
		[M.SET_INITIAL_DATA](state, data) {
			state.apiServer = data.iss;
			state.alertId = data.sub;

			addBreadcrumb({
				category: 'call',
				message: 'Parsed call data from JWT',
				data,
				level: SentrySeverity.Info,
			});
		},
		setCallType(state, type) {
			state.callType = type;

			switch (type) {
			case 'VIDEO':
				this.registerModule('videoCalls', VideoCalls);
				loadVideoCallPlugins(this);
				break;
			case 'CHAT':
				this.registerModule('chatCall', ChatCall);
				ChatPlugin(this);
				break;
			}
		},
		setWarnings(state, warnings) {
			state.warnings = warnings;
		},
	},
	getters: {
		isLoggedIn(state) {
			return !!state.operator.rawToken;
		},
		showLoader(state) {
			return !!state.showLoader;
		},
		showError(state) {
			return !!state.errorMessage;
		},
	},
});

async function loadVideoCallPlugins () {
	createControllerWebSocketPlugin({ namespace: 'videoCalls' })(store);
	createWebRTCPlugin({
		namespace: 'videoCalls',
		remoteMediaEl: 'remoteVideo',
		localMediaEl: 'localAudio',
		media: {
			video: false,
			audio: !store.state.videoCalls.useSIP,
		},
		useLocalAudio: !store.state.videoCalls.useSIP,
	})(store);
}

export default store;
