<template>
	<form @submit.prevent="doLogin" class="login-form" :style="cssVars">
		<h1 class="login-title">SafeBanker Login</h1>
		<input type="text" v-model="input.userid" class="login-input" placeholder="User ID" autofocus>
		<input type="submit" value="Accept Call" class="login-button">

		<div class="error-wrapper" v-if="$store.warnings && $store.warnings.length > 0">
			<div class="text-wrapper">
				<div class="title">Warning</div>
				<div class="subtitle">The following warnings have been detected.</div>
				<ul v-for="warning in $store.warnings" v-bind:key="warning">
					<li>{{warning}}</li>
				</ul>
			</div>
		</div>
	</form>
</template>

<script>
export default {
	name: 'LoginForm',
	data: () => {
		return {
			input: {
				userid: '',
			},
		};
	},
	methods: {
		doLogin() {
			this.$store.dispatch('doLogin', { userid: this.input.userid });
		},
	},
	computed: {
		cssVars () {
			return {
				'--version': `'${process.env.VUE_APP_VERSION}'`,
				'--year': `'${new Date().getFullYear()}'`,
			};
		},
	},
};
</script>

<style lang="scss">
.login-form {
	margin: 20px auto;
	width: 300px;
	padding: 30px 25px;
	background: white;
	border: 1px solid #c4c4c4;

	&::after {
		color: #c0c0c0;
		content: "\00a9" var(--year) "\00a0SaferMobility, LLC \00B7\00a0" var(--version);
		display: block;
		margin-top: 1em;
		text-align: center;
	}

	h1.login-title {
		margin: -28px -25px 25px;
		padding: 15px 25px;
		line-height: 30px;
		font-size: 25px;
		font-weight: 300;
		color: #ADADAD;
		text-align:center;
		background: #f7f7f7;
	}

	.login-input {
		width: 285px;
		height: 50px;
		margin-bottom: 25px;
		padding-left:10px;
		font-size: 15px;
		background: #fff;
		border: 1px solid #ccc;
		border-radius: 4px;
	}
	.login-input:focus {
		border-color:#6e8095;
		outline: none;
		}
	.login-button {
		width: 100%;
		height: 50px;
		padding: 0;
		font-size: 20px;
		color: #fff;
		text-align: center;
		background: #f0776c;
		border: 0;
		border-radius: 5px;
		cursor: pointer;
		outline:0;
	}

	.login-lost
	{
		text-align:center;
		margin-bottom:0px;
	}

	.login-lost a
	{
		color:#666;
		text-decoration:none;
		font-size:13px;
	}
}
</style>
