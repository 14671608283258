<template>
	<div class="root">
		<!--<div class="pane-header">Caller Profile</div>-->
		<div class="user-info">
			<img src="../assets/profilepic.png">
			<dl class="data">
				<dt>Name</dt><dd>{{UserName}}</dd>
				<dt>Role</dt><dd>{{UserRoleName}}</dd>
				<dt>Phone</dt><dd>{{UserPhoneNumber}}</dd>
				<dt>Email</dt><dd>{{UserEmail}}</dd>
				<dt>Language</dt><dd>{{UserLanguage}}</dd>
			</dl>
		</div>
		<div class="user-info">
			<img src="../assets/branch.svg">
			<dl class="data">
				<dt>Client</dt><dd>{{OrganizationName}}</dd>
				<dt>Region</dt><dd>{{RegionName}}</dd>
				<dt>Branch</dt><dd>{{BranchName}}</dd>
				<dt>Address</dt><dd>{{BranchAddress}}</dd>
					<dd>{{BranchCity}}, {{BranchState}} {{BranchZipCode}}</dd>
					<dd>{{BranchCountry}}</dd>
				<dt>Phone</dt><dd>{{BranchPhoneNumber}}</dd>
			</dl>
		</div>
	</div>
</template>

<script>
export default {
	name: 'ProfilePane',
	props: {
		data: Object,
	},
	computed: {
		UserName() {
			return this.data.UserFirstName + ' ' + this.data.UserLastName;
		},
		BranchAddress() {
			return this.data.BranchAddress;
		},
		BranchCity() {
			return this.data.BranchCity;
		},
		BranchCode() {
			return this.data.BranchCode;
		},
		BranchCountry() {
			return this.data.BranchCountry;
		},
		BranchName() {
			return this.data.BranchName;
		},
		BranchOfficeId() {
			return this.data.BranchOfficeId;
		},
		BranchPhoneNumber() {
			return this.data.BranchPhoneNumber;
		},
		BranchState() {
			return this.data.BranchState;
		},
		BranchTimeZone() {
			return this.data.BranchTimeZone;
		},
		BranchZipCode() {
			return this.data.BranchZipCode;
		},
		NotificationType() {
			return this.data.NotificationType;
		},
		OrganizationName() {
			return this.data.OrganizationName;
		},
		RegionName() {
			return this.data.RegionName;
		},
		SecurityOfficeCode() {
			return this.data.SecurityOfficeCode;
		},
		SecurityOfficeName() {
			return this.data.SecurityOfficeName;
		},
		SecurityOfficeTimeZone() {
			return this.data.SecurityOfficeTimeZone;
		},
		UserCode() {
			return this.data.UserCode;
		},
		UserEmail() {
			return this.data.UserEmail;
		},
		UserFirstName() {
			return this.data.UserFirstName;
		},
		UserLanguage() {
			return this.data.UserLanguage;
		},
		UserLastName() {
			return this.data.UserLastName;
		},
		UserPhoneNumber() {
			return this.data.UserPhoneNumber;
		},
		UserRoleName() {
			return this.data.UserRoleName;
		},
	},
};
</script>

<style scoped lang="scss">
@import '~styles/fibonacci';
@import '~styles/colors';

$term-width: 100px;

.root {
	background: white;
	overflow-y: auto;
}

.pane-header {
	text-align: center;
	//font-size: 1.5em;
	padding: 0.25em;
	background: $light-gray;
}

.user-info {
	@extend %rowParent;
	display: flex;
	//height: 100%;
	padding: 10px;
	box-sizing: border-box;
	border-bottom: 1px solid $light-gray;
	align-items: center;

	img {
		height: 100px;
		width: 100px;
		@extend .flexChild;
		flex: 0 0 100px;
		padding: 10px;
	}

	.data {
		display: flex;
		padding: 10px;
		margin: 0;
		flex-wrap: wrap;
		box-sizing: border-box;
		align-items: flex-start;
		align-content: flex-start;
		flex: 1;

		dt, dd {
			margin: 0 0 1px;
			padding: .25rem .5rem;
			word-wrap: break-word;
			box-sizing: border-box;
		}

		dt {
			justify-content: flex-end;
			flex: 0 0 24%;
		}
		dd {
			flex: 73% 0 0;
		}

		dd + dd {
			margin-left: 24%;
		}
	}
}
</style>
