import { addBreadcrumb, setUser as sentrySetUser } from '@sentry/vue';
import { Severity as SentrySeverity } from '@sentry/types';
import Vue from 'vue';

import M from '../store/mutation-types';
import eventBus from './eventBus';

const SAFEBANKER_API_ENDPOINT = 'external/calldata';

const ACCEPT_CHAT_ENDPOINT = 'api/client/answerchatfromconsole';
const STORE_CHAT_ENDPOINT = 'api/client/writeinchat';
const END_CHAT_ENDPOINT = 'api/client/finishChat';

export function doLogin({ commit, dispatch, state }, { userid }) {
	commit(M.SHOW_LOADER);
	addBreadcrumb({
		category: 'auth',
		message: 'Login attempt with userid: ' + userid,
	});

	if (!state.apiServer) {
		commit('setError', { message: 'Unable to communicate with server. (ERR_UNKNOWN_API_SERVER)' });
		commit(M.HIDE_LOADER);
		return;
	}

	const data = new FormData();
	data.append('alertid', state.alertId);
	data.append('userid', userid);

	fetch(state.apiServer + SAFEBANKER_API_ENDPOINT, {
		method: 'POST',
		mode: 'cors',
		cache: 'no-cache',
		headers: { 'X-Requested-With': 'XMLHttpRequest' },
		body: data,
	}).then((resp) => {
		if (!resp.ok) {
			throw new Error(`Unable to communicate with server. (ERR_API_SERVER_ERROR_${resp.status})`);
		}

		sentrySetUser({ id: userid });
		// First header is a token for chat sessions
		commit(M.OPERATOR_SET_API_SESSION, resp.headers.get('Safebanker-Old-Token'));
		// Second header is a JWT for the controller, as well as other user info for the chat
		dispatch('processOperatorToken', resp.headers.get('Safebanker-Authentication'));

		return resp.json();
	}).then((json) => {
		commit('setCallType', json.NotificationType);

		commit(M.CALLDATA_SET_DATA, json);
		Vue.nextTick().then(function() {
			eventBus.$emit('recenter-map');
		});
	}).catch((err) => {
		if (err.message === 'Failed to fetch') {
			commit('setError', { message: 'Unable to communicate with server. (ERR_API_FETCH)' });
		} else {
			commit('setError', { message: err.message });
		}
	}).finally(() => {
		commit(M.HIDE_LOADER);
	});
}

export function acceptChat({ commit, dispatch, state, rootState }) {
	commit(M.SHOW_LOADER, null, { root: true });
	addBreadcrumb({
		category: 'call',
		message: 'Accepting chat with ID ' + state.chat_room_id,
	});

	if (!rootState.apiServer) {
		commit('setError', { message: 'Unable to communicate with server. (ERR_UNKNOWN_API_SERVER)' }, { root: true });
		commit(M.HIDE_LOADER, null, { root: true });
		return;
	}

	var data = {
		param_session_token: rootState.operator.api_session_token,
		param_chat_session_id: rootState.callData.ChatRoomId,
		param_security_office_id: '' + rootState.callData.SecurityOfficeId,
		param_security_office_name: rootState.callData.SecurityOfficeName,
		param_security_officer_id: '' + rootState.operator.userid,
		param_security_officer_name: rootState.operator.first_name,
		param_security_officer_email: rootState.operator.email,
		param_answer_type: 'ACCEPT',
	};

	fetch(rootState.apiServer + ACCEPT_CHAT_ENDPOINT, {
		method: 'POST',
		mode: 'cors',
		cache: 'no-cache',
		headers: { 'X-Requested-With': 'XMLHttpRequest' },
		body: JSON.stringify(data),
	}).then((resp) => {
		if (!resp.ok) {
			throw new Error(`Unable to communicate with server. (ERR_CHAT_START_SERVER_ERROR_${resp.status})`);
		}

		return resp.json();
	}).then((json) => {
		addBreadcrumb({
			category: 'call',
			message: 'Server says we answered chat call',
			data,
			level: SentrySeverity.Info,
		});
		commit('answer');
	}).catch((err) => {
		if (err.message === 'Failed to fetch') {
			commit('setError', { message: 'Unable to communicate with server. (ERR_CHAT_START_FETCH)' }, { root: true });
		} else {
			commit('setError', { message: err.message }, { root: true });
		}
	}).finally(() => {
		commit(M.HIDE_LOADER, null, { root: true });
	});
}

export function sendChatMessage({ commit, dispatch, state, rootState }, message) {
	if (!rootState.apiServer) {
		commit('setError', { message: 'Unable to communicate with server. (ERR_UNKNOWN_API_SERVER)' }, { root: true });
		commit(M.HIDE_LOADER, null, { root: true });
		return;
	}

	addBreadcrumb({
		category: 'call',
		message: 'Sending chat message to server',
		data: message,
		level: SentrySeverity.Debug,
	});

	var data = {
		param_session_token: rootState.operator.api_session_token,
		param_chat_session_id: rootState.callData.ChatRoomId,
		param_content: message.message,
		param_type: 'TEXT',
		param_console: rootState.operator.email,
	};

	fetch(rootState.apiServer + STORE_CHAT_ENDPOINT, {
		method: 'POST',
		mode: 'cors',
		cache: 'no-cache',
		headers: { 'X-Requested-With': 'XMLHttpRequest' },
		body: JSON.stringify(data),
	}).then((resp) => {
		if (!resp.ok) {
			throw new Error(`Unable to communicate with server. (ERR_CHAT_START_SERVER_ERROR_${resp.status})`);
		}

		return resp.json();
	}).then((json) => {
		commit('chatCall/chat_messages/add_local', message, { root: true });
	}).catch((err) => {
		if (err.message === 'Failed to fetch') {
			commit('setError', { message: 'Unable to communicate with server. (ERR_CHAT_SEND_FETCH)' }, { root: true });
		} else {
			commit('setError', err.message, { root: true });
		}
	});
}

export function endChat({ commit, dispatch, state, rootState }) {
	if (!rootState.apiServer) {
		commit('setError', { message: 'Unable to communicate with server. (ERR_UNKNOWN_API_SERVER)' }, { root: true });
		commit(M.HIDE_LOADER, null, { root: true });
		return;
	}

	addBreadcrumb({
		category: 'call',
		message: 'Ending chat call',
	});

	var data = {
		param_session_token: rootState.operator.api_session_token,
		param_chat_session_id: rootState.callData.ChatRoomId,
	};

	fetch(rootState.apiServer + END_CHAT_ENDPOINT, {
		method: 'POST',
		mode: 'cors',
		cache: 'no-cache',
		headers: { 'X-Requested-With': 'XMLHttpRequest' },
		body: JSON.stringify(data),
	}).then((resp) => {
		if (!resp.ok) {
			throw new Error(`Unable to communicate with server. (ERR_CHAT_START_SERVER_ERROR_${resp.status})`);
		}

		return resp.json();
	}).then((json) => {
		commit('chatCall/endCall', null, { root: true });
	}).catch((err) => {
		if (err.message === 'Failed to fetch') {
			commit('setError', { message: 'Unable to communicate with server. (ERR_CHAT_END_FETCH)' }, { root: true });
		} else {
			commit('setError', err.message, { root: true });
		}
	});
}
