import { _names as M } from '../mutation-types';
import L from 'leaflet';
import '../../lib/branch-marker';
import { addBreadcrumb } from '@sentry/minimal';

export default {
	namespaced: true,

	state: {
		AutoId: undefined,
		BranchAddress: undefined,
		BranchCity: undefined,
		BranchCode: undefined,
		BranchCountry: undefined,
		BranchLatitude: undefined,
		BranchLongitude: undefined,
		BranchName: undefined,
		BranchOfficeId: undefined,
		BranchPhoneNumber: undefined,
		BranchState: undefined,
		BranchTimeZone: undefined,
		BranchZipCode: undefined,
		ChatRoomId: undefined,
		ChatStatus: undefined,
		ControllerAddress: undefined,
		Latitude: undefined,
		Longitude: undefined,
		NotificationType: undefined,
		OrganizationId: undefined,
		OrganizationName: undefined,
		RegionName: undefined,
		RegionalOfficeId: undefined,
		SecurityOfficeCode: undefined,
		SecurityOfficeName: undefined,
		SecurityOfficeTimeZone: undefined,
		TimeAdd: undefined,
		UserCode: undefined,
		UserEmail: undefined,
		UserFirstName: undefined,
		UserId: undefined,
		UserLanguage: undefined,
		UserLastName: undefined,
		UserPhoneNumber: undefined,
		UserRoleId: undefined,
		UserRoleName: undefined,
	},
	getters: {
		map_markers(state) {
			const markers = [];

			if (state.BranchLatitude && state.BranchLongitude) {
				markers.push({
					icon: new L.BranchIcon(),
					position: { lat: state.BranchLatitude, lng: state.BranchLongitude },
					tooltip: 'Branch Location',
				});
			}

			if (state.Latitude && state.Longitude) {
				let icon = L.DefaultIcon;

				switch (state.NotificationType) {
				case 'VIDEO':
					icon = new L.Icon({
						iconUrl: require('../../assets/map-icons/active-call-video.png'),
						iconSize: [30, 55],
						iconAnchor: [15, 55],
					});
					break;
				case 'CHAT':
					icon = new L.Icon({
						iconUrl: require('../../assets/map-icons/active-call-chat.png'),
						iconSize: [30, 55],
						iconAnchor: [15, 55],
					});
					break;
				}

				markers.push({
					icon,
					position: { lat: state.Latitude, lng: state.Longitude },
					tooltip: 'User Location',
				});
			}

			return markers;
		},
	},
	mutations: {
		[M.callData.UPDATE_USER_LOCATION](state, { lat, lng }) {
			addBreadcrumb({
				category: 'call',
				message: 'Updated user location',
				data: {
					lat,
					lng,
				},
			});
			state.Latitude = lat;
			state.Longitude = lng;
		},
		[M.callData.SET_DATA](state, callData) {
			addBreadcrumb({
				category: 'call',
				message: 'Got call data from webapp',
				data: {
					callType: callData.NotificationType,
					branchId: callData.BranchOfficeId,
					userId: callData.UserId,
					chatId: callData.ChatRoomId,
				},
			});
			state.AutoId = callData.AutoId;
			state.BranchAddress = callData.BranchAddress;
			state.BranchCity = callData.BranchCity;
			state.BranchCode = callData.BranchCode;
			state.BranchCountry = callData.BranchCountry;
			state.BranchLatitude = callData.BranchLatitude;
			state.BranchLongitude = callData.BranchLongitude;
			state.BranchName = callData.BranchName;
			state.BranchOfficeId = callData.BranchOfficeId;
			state.BranchPhoneNumber = callData.BranchPhoneNumber;
			state.BranchState = callData.BranchState;
			state.BranchTimeZone = callData.BranchTimeZone;
			state.BranchZipCode = callData.BranchZipCode;
			state.ChatRoomId = callData.ChatRoomId;
			state.ChatStatus = callData.ChatStatus;
			state.ControllerAddress = callData.ControllerAddress;
			state.Latitude = callData.Latitude;
			state.Longitude = callData.Longitude;
			state.NotificationType = callData.NotificationType;
			state.OrganizationId = callData.OrganizationId;
			state.OrganizationName = callData.OrganizationName;
			state.RegionName = callData.RegionName;
			state.RegionalOfficeId = callData.RegionalOfficeId;
			state.SecurityOfficeId = callData.SecurityOfficeId;
			state.SecurityOfficeCode = callData.SecurityOfficeCode;
			state.SecurityOfficeName = callData.SecurityOfficeName;
			state.SecurityOfficeTimeZone = callData.SecurityOfficeTimeZone;
			state.TimeAdd = callData.TimeAdd;
			state.UserCode = callData.UserCode;
			state.UserEmail = callData.UserEmail;
			state.UserFirstName = callData.UserFirstName;
			state.UserId = callData.UserId;
			state.UserLanguage = callData.UserLanguage;
			state.UserLastName = callData.UserLastName;
			state.UserPhoneNumber = callData.UserPhoneNumber;
			state.UserRoleId = callData.UserRoleId;
			state.UserRoleName = callData.UserRoleName;
		},
	},
};
