import Modernizr from 'modernizr';
import Vue from 'vue';
import App from './App.vue';
import store from './store/';
import { waitForTests } from './lib/modernizr-checks';
import { init as sentryInit, addBreadcrumb } from '@sentry/vue';
import { Integrations } from '@sentry/tracing';
import VueMoment from 'vue-moment';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faCog, faPause, faPhoneSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

sentryInit({
	Vue,
	release: 'safebanker-integration@' + process.env.VUE_APP_VERSION,
	dsn: process.env.VUE_APP_RAVEN_DSN,
	logErrors: process.env.NODE_ENV !== 'production',
	integrations: [
		new Integrations.BrowserTracing(),
	],
	// Set tracesSampleRate to 1.0 to capture 100%
	// of transactions for performance monitoring.
	tracesSampleRate: 1.0,
	tracingOptions: {
		trackComponents: true,
	},
	// For development, log Sentry Breadcrumbs to console
	beforeBreadcrumb (breadcrumb) {
		// Don't log certain types of clicks
		if (breadcrumb.category === 'ui.click') {
			if (
				breadcrumb.message.includes('form.login-form') ||
				breadcrumb.message.includes('form.text-entry') ||
				breadcrumb.message.includes('vue2leaflet-map') ||
				breadcrumb.message.includes('div.error-wrapper') ||
				breadcrumb.message === 'body.vsc-initialized > div#parent'
			) {
				return null;
			}
		}

		if (process.env.NODE_ENV !== 'production' && breadcrumb.category !== 'console') {
			console.log(breadcrumb); // eslint-disable-line no-console
		}
		return breadcrumb;
	},
});

library.add(faCog, faPause, faPhoneSlash);
Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.use(VueMoment);

Vue.config.productionTip = false;

const requiredTests = {
	audio: 'Audio Support',
	eventlistener: 'Event Listener Support',
	fetch: 'Fetch API Support',
	flexbox: 'Flexbox Support',
	getusermedia: 'Microphone Capture Support',
	json: 'JSON Support',
	localstorage: 'LocalStorage Support',
	paramlessrtclocaldescription: 'Media Negotiation Support',
	peerconnection: 'Live Media Support',
	promises: '"Promise" Support',
	queryselector: 'QuerySelector Support',
	sessionstorage: 'SessionStorage Support',
	svg: 'SVG Graphics Support',
	promise_finally: 'Promise `finally` support',
};

Modernizr.addTest({
	promise_finally: () => { return typeof Promise.prototype.finally === 'function'; },
});

waitForTests(requiredTests).then(() => {
	addBreadcrumb({ message: 'Browser Tests passed' });
	window._vm = new Vue({
		render: h => h(App),
		store,
		created() {
			store._root = this;
		},
	}).$mount('#app');
}).catch(failedTests => {
	document.getElementById('app').style.display = 'none';
	document.getElementById('deps-fail').style.display = 'block';
	var list = document.getElementById('failedTestsList');

	failedTests.forEach(message => {
		var li = document.createElement('li');
		li.innerHTML = message;
		list.appendChild(li);
	});
});
