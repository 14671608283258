// These names are used within the store and its modules
export const _names = {
	SHOW_LOADER: 'show_loader',
	HIDE_LOADER: 'hide_loader',
	SET_INITIAL_DATA: 'set_initial_data',

	operator: {
		SET_TOKEN: 'set_token',
		SET_DATA: 'set_data',
		SET_API_SESSION: 'set_api_session_token',
	},

	callData: {
		SET_DATA: 'set_data',
		MAP_MARKERS: 'map_markers',
		UPDATE_USER_LOCATION: 'update_user_location',
	},
};

function buildNamespaced(ns, _names, result = {}) {
	for (const name in _names) {
		switch (typeof _names[name]) {
		case 'string':
			result[ns.replace('/', '_').toUpperCase() + name] = ns + _names[name];
			break;
		case 'object':
			buildNamespaced(ns + name + '/', _names[name], result);
			break;
		}
	}

	return result;
}

// These names are used in the code that calls into the store
export default buildNamespaced('', _names);

window.debug_names = buildNamespaced('', _names);
