import Vue from 'vue';
import parsePhoneNumberFromString from 'libphonenumber-js';
import { DateTime } from 'luxon';
import { addBreadcrumb } from '@sentry/minimal';
import { Severity } from '@sentry/types';

export class IncidentStatus {
	static get UNKNOWN () {
		return null;
	}

	static get NEW () {
		return 'new';
	}

	static get TRANSFER () {
		return 'transfer';
	}

	static get ACTIVE () {
		return 'active';
	}

	static get ENDED () {
		return 'ended';
	}

	static get CLOSEOUT () {
		return 'closeout';
	}

	static get OTHER_CONSOLE () {
		return 'other-console';
	}

	static get OTHER_CLOSEOUT () {
		return 'other-closeout';
	}
}

export class ActiveIncident {
	status = IncidentStatus.UNKNOWN;
	profile = null;
	textMessages = [];
	locations = [];
	answeredBy = undefined;
	transferTo = undefined;

	// This makes sure we only try to answer the call once on the server
	sentAnswerRequest = false;

	// TODO Add closeout data structures

	constructor (incidentId, callerId) {
		this.incidentId = incidentId;
		this.callerId = callerId;
		// NOTE: 'US' here is a **fallback** for if no country code is provided.
		// This exists for legacy reasons. Any phone number that comes into the
		// system with a country code for another country will still be parsed correctly.
		const phoneNumber = parsePhoneNumberFromString(callerId, 'US');
		// The phone number in E.164 format with the plus stripped off
		this.cleanedCallerId = phoneNumber.format('E.164').substring(1);
		// The phone number for display
		this.formattedCallerId = phoneNumber.formatInternational();
	}
}

// NOTE: We only ever handle one call in this application, but we are trying
// to keep the code in sync with the other project as much as possible.
// For example, we are using a dictionary of call information.
//
// Places that differ from the frontend-web project are commented.

export default {
	namespaced: true,
	state: {
		isConnected: false,

		// NOTE: `otherConsoles` is not used in this application

		// IMPORTANT: Always use `Vue.set` and `Vue.delete` to add/remove items from this object.
		calls: {},

		// temporarily holds the callerId of the call we want to answer
		// while we are connecting to media
		tryToAnswerCallerId: null,
		activeCallerId: null,

		// NOTE: Playback information is not used in this application

		operatorUsername: null,

		// NOTE: These are not settings for whether to send
		// local media. They are settings for mute/unmute.
		// In practice, in this application, we do not ever send video,
		// even if the value is set to `true`.
		localAudioEnabled: true,
		localVideoEnabled: false,
		haveRemoteVideo: false,
		// This setting controls whether to connect local audio
		useSIP: false,
	},
	getters: {
		activeCall (state) {
			return state.calls[state.activeCallerId];
		},
		locations (state) {
			return state.calls[state.activeCallerId]?.locations || [];
		},
		currentLocation (state) {
			const locations = state.calls[state.activeCallerId]?.locations;
			if (locations?.length) {
				return locations[locations.length - 1];
			}
			return null;
		},
		textMessages (state) {
			return state.calls[state.activeCallerId]?.textMessages || [];
		},
		profile (state) {
			return state.calls[state.activeCallerId]?.profile;
		},
		getCall: (state) => (callerId, alreadyClean) => {
			return getCallFromPhoneNumber(state, callerId, alreadyClean);
		},
		canAnswerCall: (state) => (callerId, alreadyClean) => {
			const call = getCallFromPhoneNumber(state, callerId, alreadyClean);
			// TODO Is there any other status we should check here?
			return (call?.answeredBy?.length === 0 || call?.transferTo === state.operatorUsername) && call?.disconnectedBy?.length === 0;
		},
	},
	actions: {
		tryAnswerCall({ state, commit, rootState }) {
			// If we already have an active call, don't do anything here
			// Note that this could also be true if we have already clicked to
			// answer THIS call but the WebRTC connection is not yet stable.
			if (state.activeCallerId) {
				// TODO: Is there any kind of notification/error message we should
				//       be showing in this case?
				return;
			}

			commit('show_loader', null, { root: true });

			// NOTE: We do not do any pre-checks to see if we have the call information
			// here because we might not actually have it yet. In any case, since we already
			// know the caller ID we want to use, there is no reason to wait for the call info
			// to come from the controller. If we get errors (such as call already ended),
			// we'll deal with them then.

			// Marks that we are now trying to answer the call
			commit('tryAnswerCall', cleanCallerId(rootState.callData.UserPhoneNumber));
			// Actually starts the process
			// Note that this also works even if we don't have local media because we are using SIP.
			commit('startLocalMedia');
		},
		endCall ({ commit, state, getters }) {
			if (!state.activeCallerId) {
				return;
			}

			addBreadcrumb({
				category: 'call',
				message: 'Operator ended call',
				level: Severity.Info,
			});

			commit('endCall', getters.activeCall.callerId);
			commit('endPeerConnection');
			commit('stopLocalMedia');
		},
		// NOTE: Transfers removed
		// NOTE: Holds removed
		MESSAGE_callerStatus ({ commit, state, rootState }, message) {
			// NOTE: To save memory and processing, we discard information about other calls
			if (message.callerID !== rootState.callData.UserPhoneNumber) {
				return;
			}

			commit('updateCallStatus', message);

			// NOTE: Ringing calculation removed

			// Check if call is being hung up and end our media connections
			// or if we have answered the call and hide the loader
			if (message.disconnectedBy !== '' && message.answerer === state.operatorUsername) {
				commit('endPeerConnection');
				commit('stopLocalMedia');
				const msg = message.disconnectedBy === state.operatorUsername
					? `You have ended the call with ${rootState.callData.UserPhoneNumber}.`
					: `The user has ended the call with ${rootState.callData.UserPhoneNumber}.`;
				commit('setError', { message: msg, title: 'Call Ended' }, { root: true });
			} else if (message.answerer === state.operatorUsername) {
				commit('hide_loader', null, { root: true });
			}
		},
		addLocalMessage ({ commit, getters, state }, messageText) {
			const call = getters.activeCall;
			if (call) {
				const message = {
					clientType: 'Console',
					destination: call.callerId,
					message: messageText,
					source: state.operatorUsername,
					timestamp: DateTime.utc(),
				};
				message.key = `${message.timestamp}:${message.source}`;

				commit('addLocalMessage', message);
			}
		},
	},
	mutations: {
		updateCallStatus (state, message) {
			let call = getCallFromPhoneNumber(state, message.callerID);
			if (!call) {
				call = new ActiveIncident(message.incidentNumber, message.callerID);
				Vue.set(state.calls, call.cleanedCallerId, call);
			}

			call.transferTo = message.transfer;
			call.answeredBy = message.answerer;
			call.disconnectedBy = message.disconnectedBy;

			// If we already had a call from a user that needed closeout, and the
			// same user calls back again, we need to update the incident number so that
			// the newest call is the one that gets the closeout info.
			if (call.incidentId !== message.incidentNumber) {
				call.incidentId = message.incidentNumber;
			}

			// Check if call is being hung up
			if (message.disconnectedBy !== '') {
				// TODO: break down WebRTC connection - emit('webrtc.hangup')

				// Mark this call as un-clicked again, so
				// that in case the person calls right back,
				// we will be able to accept again.
				call.sentAnswerRequest = false;

				// If this is our current call, clean up the call interface
				if (state.activeCallerId === call.cleanedCallerId) {
					state.activeCallerId = null;
				}

				Vue.delete(state.calls, call.cleanedCallerId);

				return;
			}

			// Check if the call is hold/transfer
			if (call.transferTo !== null && call.transferTo.length > 0) {
				if (call.transferTo === state.operatorUsername) {
					call.status = IncidentStatus.TRANSFER;

					// HOLD is implemented as a transfer to yourself.  There is stuff we need
					// to do in that case...
					if (call.transferTo === call.answeredBy) {
						// Tear down the WebRTC connection, because we have some kind of issue
						// with PeerConnection renegotiation, so we will start from scratch every time.
						// TODO: break down WebRTC connection - emit('webrtc.hangup')
					}

					return;
				} else {
					// Check if we transferred the call away from ourselves so we can end our PC
					if (call.answeredBy === state.operatorUsername && state.activeCallerId === call.cleanedCallerId) {
						state.activeCallerId = null;
						// TODO: break down WebRTC connection - emit('webrtc.hangup')
					}
				}
			}

			// If this call is unanswered, let it ring
			if (call.answeredBy === '') {
				call.status = IncidentStatus.NEW;

				return;
			}

			// The only remaining possible status change is that someone has picked up
			// the call. That someone could be us, or it could be another console.
			if (call.answeredBy === state.operatorUsername) {
				call.status = IncidentStatus.ACTIVE;
			} else {
				call.status = IncidentStatus.OTHER_CONSOLE;
				Vue.set(state.otherConsoles, call.answeredBy, call.cleanedCallerId);
			}
		},
		tryAnswerCall (state, cleanedCallerId) {
			state.tryToAnswerCallerId = cleanedCallerId;
		},
		answerCall (state) {
			const callerId = state.tryToAnswerCallerId;
			state.tryToAnswerCallerId = null;
			state.activeCallerId = callerId;
			// The controller-websocket Vuex plugin also subscribes to this mutation
			// to tell the server we want to answer the call
		},
		endCall (state) {
			state.activeCallerId = null;
			// The controller-websocket Vuex plugin subscribes to this mutation
			// to end the active call
		},
		// NOTE: transfers and playback removed
		setSocketConnected (state, { isConnected, operatorUsername }) {
			state.isConnected = isConnected;
			state.operatorUsername = operatorUsername;
		},
		MESSAGE_error (state, message) {
			// The controller-websocket Vuex plugin subscribes to this mutation to handle errors
		},
		MESSAGE_location (state, message) {
			const call = getCallFromPhoneNumber(state, message.id);
			if (call) {
				const loc = {
					lat: message.latitude,
					lng: message.longitude,
					// TODO: Any other fields?
				};
				call.locations.push(loc);
			}
		},
		MESSAGE_profile (state, message) {
			const call = getCallFromPhoneNumber(state, message.id);
			if (call) {
				call.profile = message;
			}
		},
		MESSAGE_text (state, message) {
			const call = getCallFromPhoneNumber(state, message.source);
			if (call) {
				message.key = `${message.timestamp}:${message.source}`;

				message.timestamp = DateTime.fromSQL(message.timestamp, { zone: 'UTC' });

				if (!call.textMessages.some(msg => msg.key === message.key)) {
					call.textMessages.push(message);
				}
			}
		},
		MESSAGE_iceCandidate () {
			// NO-OP
			// The browser-webrtc Vuex plugin subscribes to this mutation
			// to accept the ICE candidates from the server
		},
		MESSAGE_answer () {
			// NO-OP
			// The browser-webrtc Vuex plugin subscribes to this mutation
			// to accept the SDP answer from the server
		},
		MESSAGE_ICEServers () {
			// NO-OP
			// The browser-webrtc Vuex plugin subscribes to this mutation
			// to accept the list of ICE servers from the server and start the ICE process
		},
		// NOTE: transfers and playback removed
		startLocalMedia () {
			// NO-OP
			// The browser-webrtc Vuex plugin subscribes to this mutation
			// to start the local media stream(s)
		},
		haveLocalMedia (_state, { error }) {
			// The browser-webrtc Vuex plugin calls this mutation when it
			// has connected to the browser's media input(s)
			if (error) {
				// TODO: handle errors
			}
		},
		foundLocalIceCandidate () {
			// NO-OP
			// The controller-websocket Vuex plugin subscribes to this mutation
			// to send local ICE candidates to the server
		},
		peerConnectionState (_state, connectionState) {
			if (connectionState === 'connected') {
				// TODO: Answer the call here
			}
			// TODO: Add error handling for ICE failures and/or any other status changes.
			//       See https://developer.mozilla.org/en-US/docs/Web/API/RTCPeerConnection/connectionState#rtcpeerconnectionstate_enum
			//       and https://developer.mozilla.org/en-US/docs/Web/API/RTCPeerConnection/iceConnectionState#rtciceconnectionstate_enum
		},
		setLocalOffer () {
			// NO-OP
			// The controller-websocket Vuex plugin subscribes to this mutation
			// to send the local SDP offer to the server
		},
		requestIceServers () {
			// NO-OP
			// The controller-websocket Vuex plugin subscribes to this mutation
			// to request the ICE server list from the server
		},
		endPeerConnection () {
			// The browser-webrtc Vuex plugin subscribes to this mutation
			// to close the RTCPeerConnection
		},
		stopLocalMedia () {
			// The browser-webrtc Vuex plugin subscribes to this mutation
			// to end the local media stream(s)
		},
		localAudioEnabled (state, isEnabled) {
			// The browser-webrtc Vuex plugin subscribes to this mutation
			// to actually do the muting/unmuting.
			// Here we store the value so it can be displayed in the web interface
			state.localAudioEnabled = isEnabled;
		},
		localVideoEnabled (state, isEnabled) {
			// The browser-webrtc Vuex plugin subscribes to this mutation
			// to actually do the muting/unmuting.
			// Here we store the value so it can be displayed in the web interface
			state.localVideoEnabled = isEnabled;
		},
		haveRemoteVideo (state, haveRemoteVideo) {
			state.haveRemoteVideo = haveRemoteVideo;
		},
		addLocalMessage (state, message) {
			const call = getCallFromPhoneNumber(state, message.destination, false);
			if (call) {
				call.textMessages.push(message);
			}
			// The controller-websocket Vuex plugin subscribes to this mutation
			// to send the message to the server
		},
		setUseSIP(state, useSIP) {
			state.useSIP = useSIP;
		},
	},
};

function cleanCallerId (number) {
	// NOTE: 'US' here is a **fallback** for if no country code is provided.
	// This exists for legacy reasons. Any phone number that comes into the
	// system with a country code for another country will still be parsed correctly.
	const phoneNumber = parsePhoneNumberFromString(number, 'US');
	// The phone number in E.164 format with the plus stripped off
	return phoneNumber.format('E.164').substring(1);
}

function getCallFromPhoneNumber (state, number, alreadyClean = false) {
	const formattedNumber = alreadyClean ? number : cleanCallerId(number);
	return state.calls[formattedNumber];
}
