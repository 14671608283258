'use strict';

import BaseMessage from '../base-message';

const SDP = 'sdp';

const MESSAGE_TYPE = 'offer';
const FIELDS = [SDP];
const SETTABLE = null;

export default class OfferSDPMessage extends BaseMessage {
	constructor (values) {
		super(MESSAGE_TYPE, values, FIELDS, SETTABLE);
	}
}
