// Very loosely based on, using Vuex mutations for signalling instead of EventEmitters:
//  - https://github.com/simplewebrtc/SimpleWebRTC/blob/2fe8ceabbbc3457dade5a3adbebb79b1db101c9b/src/simplewebrtc.js
//  - https://github.com/simplewebrtc/SimpleWebRTC/blob/2fe8ceabbbc3457dade5a3adbebb79b1db101c9b/src/webrtc.js

import 'webrtc-adapter';
import LocalMedia from '../../lib/media/local-media';
import _merge from 'lodash.merge';
import { Severity as SentrySeverity } from '@sentry/types';
import { addBreadcrumb } from '@sentry/minimal';

class BrowserWebRTCPlugin {
	constructor (store, opts = {}) {
		const defaults = {
			namespace: 'videoCalls',
			localMediaEl: '',
			remoteMediaEl: '',
			// Which kind of local media to use
			media: {
				video: false,
				audio: true,
			},
			autoRequestMedia: false,
			peerConnectionConstraints: {
				optional: [
					{ DtlsSrtpKeyAgreement: true },
				],
			},
			// The option below gets set to `false` for operators who use SIP
			useLocalAudio: true,
		};

		this.config = _merge(
			{},
			defaults,
			opts,
		);

		const namespace = this.config.namespace;

		this.localStream = new LocalMedia();
		this.pc = null;
		this.remoteStream = null;

		const unsubscribe = store.subscribe((mutation) => {
			switch (mutation.type) {
			case `${namespace}/startLocalMedia`:
				(async () => {
					try {
						// If we don't already have local media, start it
						if (this.localStream.streams.length === 0) {
							// TODO: Catch and handle exceptions
							const stream = await this.startLocalMedia();
							store.commit(`${namespace}/haveLocalMedia`, { media: stream });
							store.commit(`${namespace}/requestIceServers`);
						}
					} catch (err) {
						store.commit(`${namespace}/haveLocalMedia`, { error: err });
					}
				})();
				break;
			case `${namespace}/stopLocalMedia`:
				this.stopLocalMedia();
				break;
			case `${namespace}/endPeerConnection`:
				(async () => {
					await this.endPeerConnection(store);
				})();
				break;
			case `${namespace}/MESSAGE_answer`:
				(async () => {
					if (this.pc) {
						const remoteDescription = new RTCSessionDescription(mutation.payload);
						await this.pc.setRemoteDescription(remoteDescription);
					}
				})();
				break;
			case `${namespace}/MESSAGE_ICEServers`: {
				(async () => {
					// WebRTC libjingle library uses the different field names from browser.
					// need to perform the translation between these formats
					const iceServers = mutation.payload.list.map((server) => {
						return {
							url: server.uri,
							username: server.username,
							credential: server.password,
						};
					});

					if (!this.pc) {
						this.pc = await this.createPeerConnection(store, iceServers);
					}
				})();
				break;
			}
			case `${namespace}/MESSAGE_iceCandidate`:
				(async () => {
					if (this.pc) {
						await this.pc.addIceCandidate(mutation.payload);
					}
				})();
				break;
			case `${namespace}/localAudioEnabled`:
				this.localStream.audioEnabled = mutation.payload;
				break;
			case `${namespace}/localVideoEnabled`:
				this.localStream.videoEnabled = mutation.payload;
				break;
			case `${namespace}/setUseSIP`:
				this.config.media.audio = !mutation.payload;
				break;
			case 'setError':
			case 'setErrorIfNone':
				unsubscribe();
				this.endPeerConnection(store);
				this.stopLocalMedia();
				break;
			}
		});

		if (this.config.autoRequestMedia) {
			this.startLocalMedia();
		}
	}

	/**
	 * Start local media
	 *
	 * Throws exception if unable to start
	 */
	async startLocalMedia () {
		// We need to override the default constraints here because when we
		// create the object we didn't know yet if we were using SIP
		const stream = await this.localStream.start(this.config.media);
		const container = this.localMediaContainer;
		if (container && stream) {
			container.srcObject = stream;
		}
		return stream;
	}

	stopLocalMedia () {
		if (this.localStream) {
			this.localStream.stop();
		}
	}

	async endPeerConnection (store) {
		if (this.pc && this.pc.signalingState !== 'closed') {
			this.pc.close();
			this.pc = null;
			// TODO: DRY this
			store.commit(`${this.config.namespace}/haveRemoteVideo`, false);
			this.remoteStream = null;
			var videoEl = this.remoteMediaContainer;
			if (videoEl) {
				videoEl.src = '';
			}
		}
	}

	// this accepts either element ID or element
	// and either the video tag itself or a container
	// that will be used to put the video tag into.
	get localMediaContainer () {
		var el = getElement(this.config.localMediaEl);
		// Check whether we are using video (with or without audio), or audio only
		if (this.config.media.video) {
			if (el && el.tagName === 'VIDEO') {
				return el;
			} else if (el) {
				var video = document.createElement('video');
				el.appendChild(video);
				return video;
			}
		} else {
			if (el && el.tagName === 'AUDIO') {
				return el;
			} else if (el) {
				var audio = document.createElement('audio');
				el.appendChild(audio);
				return audio;
			}
		}

		return undefined;
	}

	get remoteMediaContainer () {
		return getElement(this.config.remoteMediaEl);
	}

	/**
	 * Create the RTCPeerConnection, add local media tracks,
	 * set up event listeners, and generate the offer message.
	 *
	 * This function has been totally redesigned based on
	 * https://developer.mozilla.org/en-US/docs/Web/API/WebRTC_API/Perfect_negotiation
	 *
	 * @param {Vuex.Store} store
	 * @param {Array} iceServers
	 * @param {string} namespace
	 * @param {LocalMedia} localStream
	 * @param {HTMLMediaElement} remoteMediaEl
	 * @returns {RTCPeerConnection}
	 */
	async createPeerConnection (store, iceServers) {
		const namespace = this.config.namespace;

		const pcConfig = { iceServers: iceServers };
		const pc = new RTCPeerConnection(pcConfig);

		// If we are not using SIP, add local media
		// TODO: Should we add a backup `recvonly` audio transceiver for SIP calls?
		if (!store.state.videoCalls.useSIP) {
			addBreadcrumb({
				category: 'webrtc.pc',
				message: 'Adding local stream to PeerConnection',
				level: SentrySeverity.Debug,
			});
			const stream = this.localStream.streams[0];
			for (const track of stream.getTracks()) {
				pc.addTrack(track, stream);
			}
		}

		// Since we are not sending video, we need to add a video transceiver
		// so that the other side knows we want to receive it.
		const videoTransceiver = pc.addTransceiver('video');
		videoTransceiver.direction = 'recvonly';

		pc.ontrack = ({ track, streams }) => {
			track.onunmute = () => {
				if (this.remoteMediaContainer.srcObject) {
					return;
				}
				this.remoteMediaContainer.srcObject = streams[0];
			};
		};

		pc.onnegotiationneeded = async () => {
			try {
				await pc.setLocalDescription();
				store.commit(`${namespace}/setLocalOffer`, pc.localDescription);
			} catch (err) {
				// TODO: Error handling
			}
		};

		pc.onicecandidate = ({ candidate }) => store.commit(`${namespace}/foundLocalIceCandidate`, candidate);

		pc.onconnectionstatechange = () => {
			store.commit(`${namespace}/peerConnectionState`, pc.connectionState);
			addBreadcrumb({
				category: 'webrtc.pc',
				message: 'PeerConnection State changed to ' + pc.connectionState,
				level: SentrySeverity.Debug,
			});

			// TODO: There should be a better place for this
			if (pc.connectionState === 'connected') {
				addBreadcrumb({
					category: 'webrtc.pc',
					message: 'PeerConnection Established, answering call',
					level: SentrySeverity.Debug,
				});
				store.commit(`${namespace}/answerCall`);
				store.commit(`${namespace}/haveRemoteVideo`, true);
			}
		};

		return pc;
	}
}

export default function createWebRTCPlugin (opts = {}) {
	return function (store) {
		return new BrowserWebRTCPlugin(store, opts);
	};
}

function getElement (idOrEl) {
	if (typeof idOrEl === 'string') {
		return document.getElementById(idOrEl);
	} else {
		return idOrEl;
	}
}
