'use strict';

import BaseMessage from './base-message';

const ID = 'id';
const CLIENT_TYPE = 'clientType';
const PASSWORD = 'password';
const NONCE = 'nonce';
const VERSION = 'version';

const MESSAGE_TYPE = 'connect';
const FIELDS = [ID, CLIENT_TYPE, PASSWORD, NONCE, VERSION];
const SETTABLE = [ID, PASSWORD, NONCE, VERSION];

export default class ConnectMessage extends BaseMessage {
	constructor (values) {
		super(MESSAGE_TYPE, values, FIELDS, SETTABLE);
	}
}
