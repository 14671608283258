<template>
	<div class="error-wrapper">
		<div class="text-wrapper">
			<div class="title" v-html="title"></div>
			<div class="subtitle" v-html="message"></div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'ErrorPane',
	props: {
		message: String,
		title: {
			default: 'Error',
			type: String,
		},
	},
};
</script>

<style scoped lang="scss">
.error-wrapper {
	font-family: sans-serif;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.text-wrapper {
	background: rgba(255, 255, 255, 0.5);
	padding: 2rem;
	border-radius: 2rem;
	text-align: center;
	min-width: 250px;
	margin: 2em;
}

.title {
	font-size: 4rem;
	font-weight: 700;
	color: #EE4B5E;
}

.subtitle {
	font-size: 2rem;
	font-weight: 700;
	color: #333;
}
</style>
