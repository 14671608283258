import { addBreadcrumb, captureException } from '@sentry/minimal';
import { Severity as SentrySeverity } from '@sentry/types';
import rs from 'jsrsasign';
import Vue from 'vue';

import M from '../store/mutation-types';

export function processInitialData({ $store }) {
	const pubKey = rs.KEYUTIL.getKey(process.env.VUE_APP_JWT_PUBLIC_KEY);
	const qs = window.location.search.substring(1);
	const params = new URLSearchParams(qs);
	const token = params.get('body');

	// Check if the token is present
	if (!token) {
		$store.commit('setError', { message: 'Invalid Link. Missing Token.', report: false });
		return;
	}

	// Check if the token is well-formed
	const tokenData = new rs.KJUR.jws.JWS();
	try {
		tokenData.parseJWS(token);
	} catch (ex) {
		$store.commit('setError', { message: 'Invalid Link. Link query string is malformed.', report: false });
		captureException(ex);
		return;
	}

	// Check if the token is signed and unexpired
	try {
		if (!rs.KJUR.jws.JWS.verifyJWT(token, pubKey, { alg: ['ES256'] })) {
			$store.commit('setError', { message: 'Invalid Link. Link signature is invalid or expired.' });
			return;
		}
	} catch (ex) {
		$store.commit('setError', { message: 'Invalid Link. Signature verification failed.', report: false });
		captureException(ex);
		return;
	}

	$store.commit(M.SET_INITIAL_DATA, JSON.parse(tokenData.parsedJWS.payloadS));

	// If a user id was provided in the URL, log in immediately
	if (params.get('user')) {
		Vue.nextTick(function() {
			addBreadcrumb({
				category: 'auth',
				message: 'Auto-login for User ID in URL parameter: ' + params.get('user'),
				level: SentrySeverity.Debug,
			});
			$store.dispatch('doLogin', { userid: params.get('user') });
		});
	}
}

export function processOperatorToken({ commit, dispatch, state }, token) {
	const tokenData = new rs.KJUR.jws.JWS();
	try {
		tokenData.parseJWS(token);
	} catch (ex) {
		commit('setError', { message: 'Server returned invalid token.', report: false });
		captureException(ex);
		return;
	}

	// NOTE: This function DOES NOT validate the JWT since it is not safe to save the
	// shared SECRET in a public file. However, since this token is used to connect to
	// a server (via WS), it is easily determined if it is invalid at that point.

	commit(M.OPERATOR_SET_TOKEN, token);
	commit(M.OPERATOR_SET_DATA, JSON.parse(tokenData.parsedJWS.payloadS));
}
