'use strict';

export default class BaseMessage {
	constructor (type, initial, fields, settable) {
		// Make the internal properties non-enumerable.
		// This way, we don't need a custom `toJson` to hide them.
		Object.defineProperty(this, '_fields', { enumerable: false, writable: true });
		Object.defineProperty(this, '_settable', { enumerable: false, writable: true });
		Object.defineProperty(this, '_data', { enumerable: false, writable: true });

		Object.defineProperty(this, 'type', { get: () => type, enumerable: true });

		if (initial && initial.type) {
			// Make sure the type from the data is the same as the type we expect
			// No duck-typing here!
			if (initial.type !== type) {
				throw new TypeError(`Attempting to load data from message type "${initial.type}" into message type "${type}"`);
			}
		}

		this._fields = fields || initial.keys();
		this._settable = settable || this._fields;
		this._data = {};
		for (const prop in initial) {
			// Make sure the property is part of this object, not a parent,
			// and that we aren't trying to overwrite the `type`
			if (Object.prototype.hasOwnProperty.call(initial, prop) && prop !== 'type') {
				// Use `trySetProp` here to make sure we are only setting
				// fields that are defined
				trySetProp.call(this, prop, initial[prop], this._fields);
			}
		}

		this._fields.forEach((prop) => {
			var newprop = {
				enumerable: true,
				get: () => this.getProp(prop),
			};
			if (this._settable.includes(prop)) {
				// No need to use `trySetProp` here,
				// because we only defined setters for ones we needed
				newprop.set = (value) => (this._data[prop] = value);
			}
			Object.defineProperty(this, prop, newprop);
		});
	}

	setProp (key, value) {
		trySetProp.call(this, key, value, this._settable);
	}

	getProp (key) {
		// Explicit "or null" to force them all to be returned when calling toJSON
		if (typeof this._data[key] === 'undefined') {
			return null;
		}
		return this._data[key];
	}
}

function trySetProp (key, value, allowed) {
	if ((!(allowed instanceof Array)) || allowed.includes(key)) {
		this._data[key] = value;
	} else {
		throw new TypeError(`Message does not allow setting field "${key}"`);
	}
}
