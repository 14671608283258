<template>
	<div class="root">
		<div class="messages" ref="messageList" @scroll.passive="updateUserScrolled">
			<div v-for="m in messages" :key="'' + m.timestamp + m.source" class="msg" :class="m.source === operatorId ? 'right' : 'left'">
				<p>{{m.message}}</p>
				<span>{{ formatDateTime(m.timestamp) }}</span>
			</div>
		</div>
		<form class="text-entry" @submit.prevent="sendMessage">
			<textarea @keydown.enter="sendMessage" v-model="newMessage" placeholder="Send Chat Message
(Enter or click to send)"></textarea>
			<button title="Send" type="submit">
				<img src="../assets/send-chat.svg" width="20px">
			</button>
		</form>
	</div>
</template>

<script>
import { formattedDateTime, getNow } from '../lib/luxon-formatter';

export default {
	name: 'ChatPane',
	data() {
		return {
			newMessage: '',
			userScrolled: false,
		};
	},
	computed: {
		operatorId() {
			return this.$store.getters['operator/controllerUserId'];
		},
	},
	props: {
		messages: Array,
	},
	watch: {
		// When new messages come in, scroll the pane unless the user has scrolled it up.
		messages(/* newValue, oldValue */) {
			this.$nextTick(function() {
				const el = this.$refs.messageList;
				const doScroll = !this.userScrolled;
				if (doScroll) {
					el.scrollTop = el.scrollHeight;
				}
			});
		},
	},
	methods: {
		formatDateTime (timestamp) {
			return formattedDateTime(timestamp, 'timestamp', null);
		},
		updateUserScrolled(e) {
			const el = this.$refs.messageList;
			this.userScrolled = !(el.scrollHeight - el.scrollTop === el.clientHeight);
		},
		sendMessage(e) {
			e.preventDefault();
			if (this.newMessage.trim().length > 0) {
				const msg = {
					source: this.$store.getters['operator/controllerUserId'],
					destination: this.$store.state.callData.UserPhoneNumber,
					message: this.newMessage,
					timestamp: getNow(),
				};

				// These need to be done differently because the video call commits
				// it directly and then it gets sent over the WebSocket while the
				// chat call sends it as a POST, and when that is successful commits
				// it and it is also sent over the WebSocket.
				switch (this.$store.state.callType) {
				case 'VIDEO':
					this.$store.dispatch('videoCalls/addLocalMessage', this.newMessage);
					break;
				case 'CHAT':
					this.$store.dispatch('chatCall/post_chat', msg);
				}
			}
			this.newMessage = '';
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '~styles/fibonacci';
@import '~styles/colors';

.root {
	background: $light-gray;
	display: flex;
	height: 100%;

	@extend %columnParent;
}

.messages {
	display: flex;
	flex-direction: column;
	flex: 1;
	overflow-y: scroll;

	.msg {
		//flex: 1 1 auto;
		display: block;
		clear: both;

		p {
			border-radius: 5px;
			padding: 8px;
			margin: 4px 12px 0;
			max-width: 80%;
			position: relative;
		}
		span {
			margin: 4px 12px 4px;
			font-size: 0.75em;
		}
	}

	.left {
		p {
			background-color: #1f3159;
			color: #fff;
			float: left;
		}
		span {
			float: left;
			clear: left;
		}

		p::before {
			content: '';
			position: absolute;
			width: 0;
			height: 0;
			left: -8px;
			top: 8px;
			border-top: 4px solid transparent;
			border-right: 8px solid #1f3159;
			border-bottom: 4px solid transparent;
		}
	}

	.right {
		p {
			background-color: #fff;
			color: #1f3159;
			float: right;
		}
		span {
			float: right;
			clear: right;
		}

		p::after {
			content: ''; position: absolute;
			width: 0;
			height: 0;
			right: -8px;
			bottom: 8px;
			border-top: 4px solid transparent;
			border-left: 8px solid #fff;
			border-bottom: 4px solid transparent;
		}

	}
}

.text-entry {
	flex: 4em 0 0;
	@extend %rowParent;
	box-sizing: border-box;

	textarea {
		flex: 1;
		padding: 4px;
		box-sizing: border-box;
	}
	button {
		box-sizing: border-box;
		cursor: pointer;
		flex: 4em 0 0;
		background: rgb(31, 48, 88);
		margin: 0;
		border: none;
		box-shadow: none;
	}
}
</style>
