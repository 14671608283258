import moment from 'moment';

export default {
	namespaced: true,

	state: {
		// Each message is an object:
		// {
		//     source: String,
		//     destination: String,
		//     timestamp: DateTime,
		//     message: String,
		// }

		messages: [],
	},
	mutations: {
		add_local(state, message) {
			state.messages.push(message);
		},
		add_remote(state, message) {
			// Make sure we can parse the timestamp
			message.timestamp = moment(message.timestamp);

			state.messages.push(message);
		},
	},
};
